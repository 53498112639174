import { ICarModel } from "interfaces/api_interfaces";
import { ConfiguratorMetadata } from "types/configurator";
import { Region } from "./regions_new";

/* Payments redirect relative paths */
export const PAYMENT_SUCCESS_URL = "/order-completed";
export const PAYMENT_FAILURE_URL = "/shop/cart";

/* Names in local storage */
export const CART_TOKEN = "cart-token";

/* Configurator metadata (i.e. addonCategires etc.) */
export const CONFIGURATOR_METADATA: ConfiguratorMetadata[] = [
  // Define all confgurator extra options (such as addonCategories) here
  {
    configuratorId: 7,
    addonCategories: {
      eu: "41",
      us: "17",
    },
  },
];

export const DEFAULT_CONFIGURATOR_ADDON_CATEGORY: Record<Region, string> = {
  // Default add-on category id for configurator (if not found in CONFIGURATOR_METADATA above)
  eu: "41",
  us: "17",
};

// Category ID name for shop products
export const SHOP_PRODUCT_CATEGORY_EU = "20";
export const SHOP_PRODUCT_CATEGORY_US = "16";
// Configurator default car
export const CONFIGURATOR_DEFAULT_CAR: ICarModel = {
  id: 2,
  name: "Tesla Model S",
  brand_name: "Tesla",
};

export const SNACKBAR_HIDE_DURATION = 2000;

// Static site generation invalidation time in seconds.
export const REVALIDATE_TIME = 5 * 60; // 5 minutes

export const US_COUNTRIES = { US: "United States (US)" };

export const ERR_SHIPPING = "ERR_UNAVAILABLE_SHIPPING";
// Default country for billing and shipping address
export const DEFAULT_EU_BILLING_COUNTRY = "DE";
export const DEFAULT_WORLD_BILLING_COUNTRY = "US";
